import React, { useState, useEffect } from "react";
import { Button } from "antd";
import OilCard from "./OilCard";
import PhoneInput from "./PhoneInput";
import { submitForm } from "../api";
import { CONFIG } from "../config";

const OrderForm = ({ onSubmit }) => {
  const [prices, setPrices] = useState({
    masterPrice: 1300,
    greaseTrapPrice: 1500,
  });

  const [formData, setFormData] = useState({
    city: "",
    cafeName: "",
    street: "",
    house: "",
    phone: "8",
    quantityStandard: 0,
    greaseTrapCleaning: false,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchPrices = async () => {
      try {
        const response = await fetch(`${CONFIG.API_URL}?action=getPrices`);
        const data = await response.json();
        setPrices({
          masterPrice: data.masterPrice,
          greaseTrapPrice: data.greaseTrapPrice,
        });
      } catch (error) {
        console.error("Error fetching prices:", error);
      }
    };

    fetchPrices();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handlePhoneChange = (value) => {
    setFormData((prevData) => ({ ...prevData, phone: value }));
  };

  const handleQuantityChange = (type, change) => {
    const field = type === "standard" ? "quantityStandard" : "quantityPremium";
    setFormData((prevData) => ({
      ...prevData,
      [field]: Math.max(0, prevData[field] + change),
    }));
  };

  const handleGreaseTrapToggle = () => {
    setFormData((prevData) => ({
      ...prevData,
      greaseTrapCleaning: !prevData.greaseTrapCleaning,
    }));
  };

  const handleSubmit = async (e) => {
    if (e && e.type === "submit") {
      e.preventDefault();
      setIsSubmitting(true);

      const totalLitersStandard = formData.quantityStandard * 10;
      const totalPriceStandard = formData.quantityStandard * prices.masterPrice;
      const totalPriceGreaseTrap = formData.greaseTrapCleaning
        ? prices.greaseTrapPrice
        : 0;

      const submissionData = {
        ...formData,
        totalLitersStandard,
        totalPriceStandard,
        totalPriceGreaseTrap: formData.greaseTrapCleaning
          ? prices.greaseTrapPrice
          : null,
      };

      try {
        await submitForm(submissionData);
        console.log("Data sent successfully");
        onSubmit(submissionData);
      } catch (error) {
        console.error("Error!", error.message);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="max-w-xl mx-auto bg-gray-100 p-1 rounded-lg shadow"
    >
      <div className="svg-background">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 300 200"
          style={{
            position: "absolute",
            width: "100%",
            height: "105%",
            top: 0,
            left: 0,
            zIndex: -1,
          }}
        >
          <g className="opacity-50">
            <image
              xlinkHref="./images/sunfloweropac.png"
              x="0"
              y="0"
              height="400"
              width="400"
            />
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 150 150"
              to="360 150 150"
              dur="50s"
              repeatCount="indefinite"
            />
          </g>
        </svg>
        <div className="mb-2">
          <input
            id="city"
            name="city"
            type="text"
            placeholder="Укажите Ваш город"
            required
            className="w-full px-3 py-2 border bg-green-100 bg-opacity-10 border-gray-300 rounded focus:border-orange-500"
            value={formData.city}
            onChange={handleInputChange}
          />
        </div>
        <div className="mb-2">
          <input
            id="cafeName"
            name="cafeName"
            type="text"
            placeholder="Укажите название кафе"
            required
            className="w-full px-3 py-2 border border-gray-300 rounded focus:border-orange-500"
            value={formData.cafeName}
            onChange={handleInputChange}
          />
        </div>
        <div className="flex mb-2">
          <div className=" mr-2 wide-input">
            <input
              id="street"
              name="street"
              type="text"
              placeholder="Укажите улицу"
              required
              className="w-full px-3 py-2 border border-gray-300 rounded focus:border-orange-500"
              value={formData.street}
              onChange={handleInputChange}
            />
          </div>
          <div className="narrow-input">
            <input
              id="house"
              name="house"
              type="text"
              placeholder="Дом"
              required
              className="w-full px-3 py-2 border border-gray-300 rounded focus:border-orange-500"
              value={formData.house}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="phone-board mb-2">
          <label htmlFor="phone" className="ml-3 block text-lg text-gray-500">
            Укажите контакт для связи
          </label>
          <PhoneInput value={formData.phone} onChange={handlePhoneChange} />
        </div>
      </div>
      <div className="w-full bg-opacity-90">
        <p className="mt-4 mb-3 text-[16px] text-center text-gray-700">
          Дополнительно можно заказать фритюрное масло или очистку жироуловителя
        </p>
      </div>
      <div className="flex items-center justify-center gap-4 mb-6">
        <OilCard
          type="standard"
          name="Master"
          price={prices.masterPrice}
          quantity={formData.quantityStandard}
          onQuantityChange={handleQuantityChange}
        />
        <OilCard
          type="greaseTrap"
          name="Очистка жироуловителя"
          price={prices.greaseTrapPrice}
          isGreaseTrapSelected={formData.greaseTrapCleaning}
          onGreaseTrapToggle={handleGreaseTrapToggle}
        />
      </div>
      <Button
        type="submit"
        htmlType="submit"
        loading={isSubmitting}
        className="w-full text-[20px] h-9"
        style={{
          backgroundColor: isSubmitting ? "#faad14" : "#52c41a",
          borderColor: isSubmitting ? "#faad14" : "#52c41a",
        }}
      >
        {isSubmitting ? "Оформляем" : "Подать заявку"}
      </Button>
    </form>
  );
};

export default OrderForm;
