import React, { useCallback } from "react";
import OrderForm from "../components/OrderForm";

const HomePage = ({ onOrderSubmit }) => {
  const handleOrderSubmit = useCallback(
    (data) => {
      onOrderSubmit(data);
    },
    [onOrderSubmit]
  );

  return (
    <div className="bg-gray-200 ">
      <h1 className="text-center text-[18px] mb-1">
        Форма приема заявок на вывоз и/или поставку масла
      </h1>
      <OrderForm onSubmit={handleOrderSubmit} />
    </div>
  );
};

export default HomePage;
