import React from "react";

const ConfirmationPage = ({ onNewOrder, data }) => {
  return (
    <div className="mt-[55px] ml-[65px] bg-[#d8f0ca] rounded-lg p-[25px]">
      <h1 className="text-center text-2xl mb-4">Заявка принята</h1>
      {data && (
        <div className="mb-4">
          <p>Город: {data.city}</p>
          <p>Кафе: {data.cafeName}</p>
          <p>
            Адрес: {data.street}, {data.house}
          </p>
          <p>Телефон: {data.phone}</p>
          <p>Заказано Master: {data.quantityStandard} шт.</p>
          <p>Очистка жироуловителя: {data.greaseTrapCleaning ? "Да" : "Нет"}</p>
        </div>
      )}
      <button
        onClick={onNewOrder}
        className="px-[10px] w-full h-[30px] bg-green-600 text-white rounded"
      >
        Оформить новую заявку
      </button>
    </div>
  );
};

export default ConfirmationPage;
