import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";

const FadingText = styled(motion.p)`
  position: absolute;
  width: 100%;
`;

const OilCard = ({
  type,
  name,
  price,
  quantity,
  onQuantityChange,
  isGreaseTrapSelected,
  onGreaseTrapToggle,
}) => {
  const [isSelected, setIsSelected] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [priceText, setPriceText] = useState(`${price} руб./услуга`);

  const greaseTrapImages = [
    "./images/grasetrap1.jpg",
    "./images/grasetrap2.jpg",
  ];

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % greaseTrapImages.length);
  };


useEffect(() => {
  const textTimer = setInterval(() => {
    setPriceText((prevText) => ({
      text: prevText.isPrice
        ? type === "greaseTrap"
          ? "Бесплатная установка"
          : "Доставка бесплатно"
        : type === "greaseTrap"
        ? `${price} руб./в месяц`
        : `${price} руб./10л.`,
      isPrice: !prevText.isPrice,
    }));
    if (type === "greaseTrap") {
      nextSlide();
    }
  }, 3000);

  return () => clearInterval(textTimer);
}, [type, price]);



  const handleSelect = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (type === "greaseTrap") {
      onGreaseTrapToggle();
    } else {
      setIsSelected(true);
      if (quantity === 0) {
        onQuantityChange(type, 1);
      }
    }
  };

  const handleImageClick = () => {
    setShowModal(true);
  };

  const imageSrc =
    type === "greaseTrap"
      ? greaseTrapImages[currentSlide]
      : type === "standard"
       ? "./images/oil3.png"
      : "./images/solpro.jpg";

return (
  <div className="oil-card bg-gray-200 rounded-lg shadow-md w-[160px]">
    <div className="w-full h-[45px] py-[2px]">
      <h3 className="text-[15px] text-center font-semibold">{name}</h3>
    </div>
    <div className="flex items-center justify-center mx-4 w-[125px] h-[120px] rounded-md bg-white mb-[2px]">
      <img
        src={imageSrc}
        alt={type === "greaseTrap" ? "Жироуловитель" : `Масло ${name}`}
        className="w-[95px] h-[115px] p-1 bg-white cursor-pointer"
        onClick={handleImageClick}
      />
    </div>
    <div className="w-full h-[25px] price-board mb-[2px] text-center border-4 border-black rounded-full relative overflow-hidden">
      <AnimatePresence mode="wait">
        <FadingText
          key={priceText.text}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
          className={`bg-[#ede393] text-red-900 ${
            priceText.isPrice ? "text-[18px]" : "text-[15px]"
          }`}
        >
          {priceText.text}
        </FadingText>
      </AnimatePresence>
    </div>

    {type === "greaseTrap" ? (
      <button
        onClick={handleSelect}
        className={`w-[105px] text-[12px] mb-1 mt-1 ml-12 py-[3px] ${
          isGreaseTrapSelected
            ? "bg-green-500 text-white"
            : "bg-[#93c6e7] text-black"
        } rounded-full`}
      >
        {isGreaseTrapSelected ? "Выбрано" : "Выбрать"}
      </button>
    ) : !isSelected ? (
      <button
        onClick={handleSelect}
        className="w-[105px] text-[12px] mt-1 mb-1 ml-12 py-[3px] bg-[#93c6e7] text-black rounded-full"
      >
        Выбрать
      </button>
    ) : (
      <>
        <div className="quantity-selector flex justify-center items-center mb-2">
          <button
            type="button"
            onClick={() => onQuantityChange(type, -1)}
            className="px-3 py-1 bg-gray-200 rounded-l-full"
          >
            -
          </button>
          <input
            type="number"
            value={quantity}
            readOnly
            className="w-[15px] text-center border-t border-b border-gray-300"
          />
          <button
            type="button"
            onClick={() => onQuantityChange(type, 1)}
            className="px-3 py-1 bg-gray-200 rounded-r-full"
          >
            +
          </button>
        </div>
        <p className="text-center mb-2">Всего: {quantity * 10} л.</p>
        <p className="text-center mb-2">Сумма: {quantity * price} руб.</p>
      </>
    )}

    <AnimatePresence>
      {showModal && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={() => setShowModal(false)}
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
        >
          <motion.img
            src={imageSrc}
            alt={type === "greaseTrap" ? "Жироуловитель" : `Масло ${name}`}
            initial={{ scale: 0.5 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0.5 }}
            className="max-w-[80%] max-h-[80%] object-contain"
          />
        </motion.div>
      )}
    </AnimatePresence>
  </div>
);
};

export default OilCard;
